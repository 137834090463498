import "../App.css";
import AdmissionForm from "../components/AdmissionForm";
import AdmissionForm2 from "../components/AdmissionForm2";
import AdmissionForm3 from "../components/AdmissionForm3";
import AdmissionForm4 from "../components/AdmissionForm4";
import Form from "../components/Form";
import CustomizedTabs from "../components/CommonTab";
import { Box, Button } from "@mui/material";
import CivilNumberVerification from "../pages/CivilNumberVerification";
import EmailVerification from "../pages/EmailVerification";
import Acknowledgment from "../pages/Acknowledgment";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../components/Spinner";
import SuccessPage from "../pages/SuccessPage";
import SubmitPage from "../pages/SubmitPage";
import { initialFormData } from "../utils/formData";
import { validateForm } from "../utils/validateForm";
import CivilIdDocument from "../pages/CivilIdDocument";
import { BASE_URL } from "../utils/config";
import { student } from "../utils/student";

function AdmissionFormPage() {
  const steps = [
    "PERSONAL",
    "FAMILY",
    "HEALTH",
    "ACADEMIC",
    // "EMERGENCY",
    "DOCUMENT"
  ];

  const [step, setStep] = useState(-3);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [showError, setShowError] = useState(false);
  const [documentError, setDocumentError] = useState(false);
  const [documentErrorMsg, setDocumentErrorMsg] = useState("");
  const [errors, setErrors] = useState({});
  const [studentID, setStudentID] = useState("");

  const handleChange = (e, firstKey, secondKey, thirdKey, forthKey) => {
    if (e.persist) e.persist();

    setFormData((currentData) => {
      let value;
      const { type } = e.target;
      if (type === "text") {
        value =
          e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
      } else {
        value = e.target.value;
      }

      const data = { ...currentData };
      let updateData = data;
      if (firstKey) {
        updateData = updateData[firstKey];
      }

      if (secondKey) {
        updateData = updateData[secondKey];
      }

      if (thirdKey) {
        updateData = updateData[thirdKey];
      }
      if (forthKey) {
        updateData = updateData[forthKey];
      }
      updateData.value = value;

      localStorage.setItem("FormData", JSON.stringify(formData));
      return data;
    });
  };

  useEffect(() => {
    if (Object.keys(errors)?.length > 0) {
      setShowError(false);
      setErrors({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (step <= 5) {
      const isValid = validateForm(formData, setErrors, step);

      console.log(step, isValid);
      if (isValid) {
        if (step === 5) {
          submitForm();
        } else {
          setStep(step + 1);
        }
        window.scrollTo(0, 0);
      } else {
        window.scrollTo(0, 0);
        setShowError(true);
        console.log(
          "Form has errors. Please fill in all required fields.",
          errors
        );
      }
    } else {
    }
  };

  const submitForm = async () => {
    let archivements = {};

    formData.archivements.value?.forEach((data, index) => {
      if (formData.archivements.value.length > 0) {
        archivements[index] = data.value;
      }
    });

    setIsLoading(true);

    try {
      if (!documentError) {
        const response = await fetch(
          `${BASE_URL}/student/admission/application-one`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              applicationType: "pharma",
              firstName: formData.firstName.value,
              middleName: formData.middleName.value,
              lastName: formData.lastName.value,
              firstNameArabic: formData.arabicFirstName.value,
              middleNameArabic: formData.arabicMiddleName.value,
              lastNameArabic: formData.arabicLastName.value,
              dob: formData.dob.value,
              gender: formData.gender.value,
              maritalStatus: formData.maritalStatus.value,
              civilId: formData.civilId.value,
              civilIdExpiry: formData.civilIdExpiryDate.value,
              mobileOne: formData.mobileOne.value,
              mobileTwo: formData.mobileTwo.value,
              homeTelOne: formData.homeTelOne.value,
              emailOne: formData.emailOne.value,
              nationality: formData.nationality.value,
              birthCountry: formData.birthCountry.value,
              isHealthRecord: formData.isHealthRecord.value,
              healthNote: formData.healthNote.value,
              isSuspended: formData.isSuspended.value,
              suspensionNote: formData.suspensionNote.value,
              sponsorship: Object.keys(formData.sponsorship.value || {})?.[0],
              sponsorshipOtherNote: formData.sponsorshipOtherNote.value,
              sponsorshipNote: formData.sponsorshipScholarshipNote.value,
              archivements: formData.isAcademic.value ? archivements : {},
              address: {
                home: formData.address.home.value,
                block: formData.address.block.value,
                appartment: formData.address.appartment.value,
                street: formData.address.street.value,
                area: formData.address.area.value,
                jaddah: formData.address.jaddah.value,
                country: formData.address.country.value,
                po: formData.address.po.value,
                zipCode: formData.address.zipCode.value,
                governorate: formData.address.governorate.value
              },
              securityCard: formData.securityCard.value,
              securityCardExpiry: formData.securityCardExpiry.value,
              family: {
                fatherName: formData.family.fatherName.value,
                fatherNameArabic: formData.family.arabicFatherName.value,
                fatherOccup: formData.family.fatherOccup.value,
                fatherEmployer: formData.family.fatherEmployer.value,
                fatherDepartment: formData.family.fatherDepartment.value,
                fatherMobile: formData.family.fatherMobile.value,
                fatherEmail: formData.family.fatherEmail.value,
                motherName: formData.family.motherName.value,
                motherNameArabic: formData.family.arabicMotherName.value,
                motherOccup: formData.family.motherOccup.value,
                motherEmployer: formData.family.motherEmployer.value,
                motherDepartment: formData.family.motherDepartment.value,
                motherMobile: formData.family.motherMobile.value,
                motherEmail: formData.family.motherEmail.value
              },
              education: [
                ...formData.school.value?.map((data) => ({
                  name: data.name.value,
                  type: "school",
                  gpa: data.gpa.value,
                  date: data.date.value,
                  degree: data.degree.value
                })),
                ...(formData.isCollege.value
                  ? formData.college.value?.map((data) => ({
                      name: data.name.value,
                      type: "college",
                      country: data.country.value,
                      gpa: data.gpa.value,
                      date: data.date.value,
                      major: data.major.value,
                      degree: data.degree.value
                    }))
                  : [])
              ],
              scientificSubject: {
                physics: formData.scientificMarks.value[0].physics.value,
                chemistry: formData.scientificMarks.value[0].chemistry.value,
                biology: formData.scientificMarks.value[0].biology.value,
                average: formData.scientificMarks.value[0].average.value
              },
              englishTest: {
                date:
                  formData.test.testType.value === "NA"
                    ? null
                    : formData.test.testDate.value,
                score:
                  formData.test.testType.value === "NA"
                    ? null
                    : formData.test.testScore.value,
                type: formData.test.testType.value,
                otherNote:
                  formData.test.testType.value === "other"
                    ? formData.testTypeOtherNote.value
                    : null
              },
              relatives: formData.relatives.selected.value,
              relativesNote:
                formData.relatives.selected.value === "kuwait university" ||
                formData.relatives.selected.value === "mohe"
                  ? formData.relativesNote.value
                  : null,
              eligible: formData.eligible.value
            })
          }
        );

        const result = await response.json();
        console.log("RESULT", result);

        if (result.status) {
          setStudentID(result.data.id);
          const docFile = new FormData();
          console.log("INITIALIZE", docFile);

          if (formData.nationality.value === "Non-Kuwaiti") {
            docFile.append(
              "securityFront",
              formData.document.securityCardFront.value
            );
            docFile.append(
              "securityBack",
              formData.document.securityCardBack.value
            );
            docFile.append(
              "civilidFront",
              formData.document.civilidFront.value
            );
            docFile.append("civilidBack", formData.document.civilidBack.value);
          } else {
            docFile.append(
              "civilidFront",
              formData.document.civilidFront.value
            );

            docFile.append("civilidBack", formData.document.civilidBack.value);
          }
          docFile.append("id", `${result.data.id}`);

          let docFileLen = 0;
          for (const entry of docFile.entries()) {
            console.log(entry[0], entry[1]);
            docFileLen++;
          }
          const isFormDataEmpty = docFileLen === 0;
          console.log("Is FormData Empty:", isFormDataEmpty);

          if (isFormDataEmpty) {
            setStep(4);
          } else {
            try {
              const docResponse = await fetch(
                `${BASE_URL}/student/admission/document/`,
                {
                  method: "POST",
                  body: docFile
                }
              );

              const jsonDoc = await docResponse.json();
              console.log(jsonDoc);

              if (jsonDoc.status) {
                toast.success("Form submitted successfully!");
                setStep(6);
                setFormData(initialFormData);
                localStorage.removeItem("FormData");
              } else {
                setStep(4);
                setDocumentError(true);
                setDocumentErrorMsg(jsonDoc.message);
              }
            } catch (docError) {
              console.error("Document upload failed:", docError);
              setStep(4);
            }
          }
        } else {
          toast.error("Form submission failed!");
        }
      } else {
        // hit if there is document error
        const docFile = new FormData();
        if (formData.nationality.value === "Non-Kuwaiti") {
          docFile.append(
            "securityFront",
            formData.document.securityCardFront.value
          );
          docFile.append(
            "securityBack",
            formData.document.securityCardBack.value
          );
          docFile.append("civilidFront", formData.document.civilidFront.value);
          docFile.append("civilidBack", formData.document.civilidBack.value);
        } else {
          docFile.append("civilidFront", formData.document.civilidFront.value);
          docFile.append("civilidBack", formData.document.civilidBack.value);
        }
        docFile.append("id", studentID);
        console.log(docFile);

        try {
          const docResponse = await fetch(
            `${BASE_URL}/student/admission/document/`,
            {
              method: "POST",
              body: docFile
            }
          );

          const jsonDoc = await docResponse.json();
          console.log(jsonDoc);

          if (jsonDoc.status) {
            toast.success("Form submitted successfully!");
            setStep(6);
            setFormData(initialFormData);
            localStorage.removeItem("FormData");
          } else {
            setStep(4);
            setDocumentError(true);
            setDocumentErrorMsg(jsonDoc.message);
          }
        } catch (docError) {
          console.error("Document upload failed:", docError);
          setStep(4);
        }
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Form submission failed!", error);
    } finally {
      setIsLoading(false);
    }
  };

  // const submitForm = () => {
  //   let archivements = {};
  //   formData.archivements.value?.map((data, index) => {
  //     if (formData.archivements.value.length > 0) {
  //       return (archivements[index] = data.value);
  //     }
  //     return [];
  //   });
  //   setIsLoading(true);
  //   fetch(`${BASE_URL}/student/admission/application-one`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json"
  //     },
  //     body: JSON.stringify({
  //       firstName: formData.firstName.value,
  //       middleName: formData.middleName.value,
  //       lastName: formData.lastName.value,
  //       firstNameArabic: formData.arabicFirstName.value,
  //       middleNameArabic: formData.arabicMiddleName.value,
  //       lastNameArabic: formData.arabicLastName.value,
  //       dob: formData.dob.value,
  //       gender: formData.gender.value,
  //       maritalStatus: formData.maritalStatus.value,
  //       civilId: formData.civilId.value,
  //       civilIdExpiry: formData.civilIdExpiryDate.value,
  //       mobileOne: formData.mobileOne.value,
  //       mobileTwo: formData.mobileTwo.value,
  //       homeTelOne: formData.homeTelOne.value,
  //       emailOne: formData.emailOne.value,
  //       nationality: formData.nationality.value,
  //       birthCountry: formData.birthCountry.value,
  //       isHealthRecord: formData.isHealthRecord.value,
  //       healthNote: formData.healthNote.value,
  //       isSuspended: formData.isSuspended.value,
  //       suspensionNote: formData.suspensionNote.value,
  //       sponsorship: Object.keys(formData.sponsorship.value || {})?.[0],
  //       sponsorshipOtherNote: formData.sponsorshipOtherNote.value,
  //       sponsorshipNote: formData.sponsorshipScholarshipNote.value,
  //       archivements: formData.isAcademic.value === true ? archivements : {},
  //       address: {
  //         home: formData.address.home.value,
  //         block: formData.address.block.value,
  //         appartment: formData.address.appartment.value,
  //         street: formData.address.street.value,
  //         area: formData.address.area.value,
  //         jaddah: formData.address.jaddah.value,
  //         country: formData.address.country.value,
  //         po: formData.address.po.value,
  //         zipCode: formData.address.zipCode.value,
  //         governorate: formData.address.governorate.value
  //       },
  //       securityCard: formData.securityCard.value,
  //       securityCardExpiry: formData.securityCardExpiry.value,
  //       family: {
  //         fatherName: formData.family.fatherName.value,
  //         fatherNameArabic: formData.family.arabicFatherName.value,
  //         fatherOccup: formData.family.fatherOccup.value,
  //         fatherEmployer: formData.family.fatherEmployer.value,
  //         fatherDepartment: formData.family.fatherDepartment.value,
  //         fatherMobile: formData.family.fatherMobile.value,
  //         fatherEmail: formData.family.fatherEmail.value,

  //         motherName: formData.family.motherName.value,
  //         motherNameArabic: formData.family.arabicMotherName.value,
  //         motherOccup: formData.family.motherOccup.value,
  //         motherEmployer: formData.family.motherEmployer.value,
  //         motherDepartment: formData.family.motherDepartment.value,
  //         motherMobile: formData.family.motherMobile.value,
  //         motherEmail: formData.family.motherEmail.value
  //       },

  //       education: [
  //         ...formData.school.value?.map((data) => ({
  //           name: data.name.value,
  //           type: "school",
  //           gpa: data.gpa.value,
  //           date: data.date.value,
  //           degree: data.degree.value
  //         })),
  //         ...(formData.isCollege.value === true
  //           ? formData.college.value?.map((data) => ({
  //               name: data.name.value,
  //               type: "college",
  //               country: data.country.value,
  //               gpa: data.gpa.value,
  //               date: data.date,
  //               major: data.major.value,
  //               degree: data.degree.value
  //             }))
  //           : [])
  //       ],

  //       scientificSubject: {
  //         physics: formData.scientificMarks.value[0].physics.value,
  //         chemistry: formData.scientificMarks.value[0].chemistry.value,
  //         biology: formData.scientificMarks.value[0].biology.value,
  //         average: formData.scientificMarks.value[0].average.value
  //       },
  //       englishTest: {
  //         date:
  //           formData.test.testType.value === "NA"
  //             ? null
  //             : formData.test.testDate.value,
  //         score:
  //           formData.test.testType.value === "NA"
  //             ? null
  //             : formData.test.testScore.value,
  //         type: formData.test.testType.value,
  //         otherNote:
  //           formData.test.testType.value === "other"
  //             ? formData.testTypeOtherNote.value
  //             : null
  //       },
  //       relatives: formData.relatives.selected.value,
  //       relativesNote:
  //         formData.relatives.selected.value === "kuwait university" ||
  //         formData.relatives.selected.value === "mohe"
  //           ? formData.relativesNote.value
  //           : null,
  //       eligible: formData.eligible.value
  //     })
  //   })
  //     .then(async (res) => {
  //       const result = await res.json();
  //       console.log("RESULT", result);
  //       if (result.status) {
  //         const docFile = new FormData();

  //         if (formData.nationality.value === "Non-Kuwaiti") {
  //           docFile.append(
  //             "securityFront",
  //             formData.document.securityCardFront.value
  //           );
  //           docFile.append(
  //             "securityBack",
  //             formData.document.securityCardBack.value
  //           );
  //           docFile.append(
  //             "civilidFront",
  //             formData.document.civilidFront.value
  //           );
  //           docFile.append("civilidBack", formData.document.civilidBack.value);
  //         } else {
  //           docFile.append(
  //             "civilidFront",
  //             formData.document.civilidFront.value
  //           );
  //           docFile.append("civilidBack", formData.document.civilidBack.value);
  //         }
  //         docFile.append("id", `${result.data.id}`);

  //         await fetch(`${BASE_URL}/student/admission/document/`, {
  //           method: "POST",
  //           body: docFile
  //         })
  //           .then(async (response) => {
  //             await response.json();
  //             // console.log(res);
  //           })
  //           .then((data) => {
  //             // console.log(data);
  //             setStep(6);
  //             setFormData(initialFormData);
  //             localStorage.removeItem("FormData");
  //             setIsLoading(false);
  //           })
  //           .catch((error) => {
  //             setStep(4);
  //             console.error("Error:", error);
  //           });
  //       } else {
  //         toast.error("Form submission failed!");
  //       }
  //     })
  //     .catch((err) => {
  //       toast.error("Form submission sgdd failed!", err);
  //     })
  //     .finally(() => setIsLoading(false));
  // };

  const handleCancel = () => {
    setStep(-3);
    localStorage.removeItem("otp");
    localStorage.removeItem("FormData");

    setFormData(initialFormData);
  };
  const handleBack = () => {
    setStep(step - 1 >= 0 ? step - 1 : 0);
  };
  // for -ve steps
  const stepHandler = () => {
    setStep(step + 1);
  };

  useEffect(() => {
    if (localStorage.getItem("FormData")) {
      setFormData({
        ...JSON.parse(localStorage.getItem("FormData")),
        document: formData.document
      });
    }
    return () => {
      localStorage.removeItem("otp");
    };
  }, [formData.document]);
  return (
    <div className="">
      {step < 0 ? (
        step === -3 ? (
          <Acknowledgment stepHandler={stepHandler} />
        ) : step === -2 ? (
          <CivilNumberVerification
            civilId={formData.civilId}
            handleChange={handleChange}
            stepHandler={stepHandler}
          />
        ) : step === -1 ? (
          <EmailVerification
            stepHandler={stepHandler}
            email={formData.emailOne}
            handleChange={handleChange}
          />
        ) : null
      ) : step > 4 ? (
        <form onSubmit={handleSubmit}>
          {step === 5 ? (
            <>
              <SubmitPage
                showError={showError}
                errors={errors}
                formData={formData}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
              />
              {isLoading && <Spinner />}
            </>
          ) : step === 6 ? (
            <SuccessPage />
          ) : null}
        </form>
      ) : (
        <>
          <CustomizedTabs steps={steps} value={step} />
          <form onSubmit={handleSubmit}>
            {step === 0 ? (
              <AdmissionForm
                showError={showError}
                errors={errors}
                formData={formData}
                handleChange={handleChange}
              />
            ) : step === 1 ? (
              <AdmissionForm2
                showError={showError}
                errors={errors}
                formData={formData}
                handleChange={handleChange}
              />
            ) : step === 2 ? (
              <AdmissionForm3
                showError={showError}
                errors={errors}
                formData={formData}
                handleChange={handleChange}
              />
            ) : step === 3 ? (
              <AdmissionForm4
                showError={showError}
                errors={errors}
                formData={formData}
                handleChange={handleChange}
              />
            ) : step === 8 ? (
              <Form
                showError={showError}
                errors={errors}
                formData={formData}
                handleChange={handleChange}
              />
            ) : step === 4 ? (
              <CivilIdDocument
                showError={showError}
                documentError={documentError}
                documentErrorMsg={documentErrorMsg}
                errors={errors}
                formData={formData}
                handleChange={handleChange}
              />
            ) : null}
            <Box style={{ height: "4rem" }}></Box>
            <Box className="submitContainer border-t-[1px] border-t-[#0466c8]">
              <Button className={"cancelButton"} onClick={handleCancel}>
                Cancel
              </Button>
              {step > 0 ? (
                <Button className={"cancelButton"} onClick={handleBack}>
                  Back
                </Button>
              ) : null}
              <Button
                disabled={
                  // (step === 2 && formData.isHealthRecord.value === null) ||
                  // (step === 5 && !validateDoc())
                  false
                }
                className={"submitButton disabled:bg-red-100 "}
                type="submit"
              >
                Next
              </Button>
            </Box>
          </form>
        </>
      )}
      <ToastContainer />
    </div>
  );
}

export default AdmissionFormPage;
