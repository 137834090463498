import { Box } from "@mui/material";
import React, { useEffect } from "react";
import "./AdmissionForm.css";
import { Grid } from "@mui/material";
import CommonAccordion from "./CommonAccordion";
import Input from "./Input";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import "react-phone-input-2/lib/style.css";
import "./phoneInput.css";
import PhoneInput from "react-phone-input-2";
import { customBorderBottom, maxLength, isEnglish } from "../utils/methods";
import { formLabel } from "../utils/formFieldLabels";
import { countryListOptionWithBedoon } from "../utils/methods";
import FormFieldLabel from "./FormFieldLabel";
import ErrorMessage from "./ErrorMessage";
import ErrorTitle from "./ErrorTitle";
import validator from "validator";
import { isArabic, isNotArabic } from "../utils/methods";

const AdmissionForm = ({ formData, handleChange, errors, showError }) => {
  useEffect(() => {
    document.title = "AUMS • Personal";
  }, []);
  return (
    <Box sx={{ gap: "10rem" }}>
      <Box
        sx={{
          display: "flex",
          fontWeight: "bold",
          justifyContent: "space-between",
          backgroundColor: "#0466c8",
          color: "white",
          width: "100%",
          padding: "1rem"
        }}
      >
        <span>{formLabel.headings.personalPageTitle.english}</span>
        <span>{formLabel.headings.personalPageTitle.arabic}</span>
      </Box>
      <Box>
        <CommonAccordion
          defaultExpanded={true}
          arabicLabel={formLabel.headings.personalDetails.arabic}
          englishLabel={formLabel.headings.personalDetails.english}
        >
          <Grid container spacing={1}>
            {showError && <ErrorTitle />}
            <Grid item container spacing={4}>
              <Grid xs={12} md={4} item>
                <Input
                  value={formData.firstName.value}
                  handleChange={(e) => {
                    if (!isEnglish(e)) return;
                    handleChange(e, "firstName");
                  }}
                  englishLabel={formLabel.firstName.english}
                />
                {errors.firstName && (
                  <ErrorMessage errorMessage={errors.firstName} />
                )}
                <Input
                  value={formData.arabicFirstName.value}
                  handleChange={(e) => {
                    if (!isArabic(e)) return;
                    handleChange(e, "arabicFirstName");
                  }}
                  arabicLabel={formLabel.firstName.arabic}
                />
                {errors.arabicFirstName && (
                  <ErrorMessage errorMessage={errors.arabicFirstName} />
                )}
              </Grid>
              <Grid xs={12} md={4} item>
                <Input
                  value={formData.middleName.value}
                  handleChange={(e) => {
                    if (!isEnglish(e)) return;
                    handleChange(e, "middleName");
                  }}
                  englishLabel={formLabel.middleName.english}
                />
                {errors.middleName && (
                  <ErrorMessage errorMessage={errors.middleName} />
                )}
                <Input
                  value={formData.arabicMiddleName.value}
                  handleChange={(e) => {
                    if (!isArabic(e)) return;
                    handleChange(e, "arabicMiddleName");
                  }}
                  arabicLabel={formLabel.middleName.arabic}
                />
                {errors.arabicMiddleName && (
                  <ErrorMessage errorMessage={errors.arabicMiddleName} />
                )}
              </Grid>
              <Grid xs={12} md={4} item>
                <Input
                  value={formData.lastName.value}
                  handleChange={(e) => {
                    if (!isEnglish(e)) return;
                    handleChange(e, "lastName");
                  }}
                  englishLabel={formLabel.lastName.english}
                />
                {errors.lastName && (
                  <ErrorMessage errorMessage={errors.lastName} />
                )}
                <Input
                  value={formData.arabicLastName.value}
                  handleChange={(e) => {
                    if (!isArabic(e)) return;
                    handleChange(e, "arabicLastName");
                  }}
                  arabicLabel={formLabel.lastName.arabic}
                />
                {errors.arabicLastName && (
                  <ErrorMessage errorMessage={errors.arabicLastName} />
                )}
              </Grid>
              <Grid xs={12} md={4} item>
                <Input
                  value={formData.dob.value}
                  type={"date"}
                  handleChange={(e) => {
                    if (new Date(e.target.value).getFullYear() > 2099) return;
                    handleChange(e, "dob");
                  }}
                  arabicLabel={formLabel.dob.arabic}
                  englishLabel={formLabel.dob.english}
                />
                {errors.dob && <ErrorMessage errorMessage={errors.dob} />}
              </Grid>
              <Grid xs={12} md={4} item>
                <Input
                  //
                  value={formData.gender.value}
                  handleChange={(e) => handleChange(e, "gender")}
                  arabicLabel={formLabel.gender.arabic}
                  englishLabel={formLabel.gender.english}
                  option={formData.gender.option}
                  select={true}
                  name="gender"
                />
                {errors.gender && <ErrorMessage errorMessage={errors.gender} />}
                {/* <Select label={"Gender"} /> */}
              </Grid>
              <Grid xs={12} md={4} item>
                <Input
                  value={formData.maritalStatus.value}
                  handleChange={(e) => handleChange(e, "maritalStatus")}
                  arabicLabel={formLabel.maritalStatus.arabic}
                  englishLabel={formLabel.maritalStatus.english}
                  option={formData.maritalStatus.option}
                  select={true}
                  name="maritalStatus"
                />
                {errors.maritalStatus && (
                  <ErrorMessage errorMessage={errors.maritalStatus} />
                )}
              </Grid>
              <Grid xs={12} md={4} item>
                <Input
                  disabled={true}
                  value={formData.civilId.value}
                  handleChange={(e) => handleChange(e, "civilId")}
                  arabicLabel={formLabel.civilId.arabic}
                  englishLabel={formLabel.civilId.english}
                />
                {errors.civilId && (
                  <ErrorMessage errorMessage={errors.civilId} />
                )}
              </Grid>
              <Grid xs={12} md={4} item>
                <Input
                  type={"date"}
                  value={formData.civilIdExpiryDate.value}
                  handleChange={(e) => {
                    if (new Date(e.target.value).getFullYear() > 2099) return;
                    handleChange(e, "civilIdExpiryDate");
                  }}
                  arabicLabel={formLabel.civilIdExpiryDate.arabic}
                  englishLabel={formLabel.civilIdExpiryDate.english}
                />
                {errors.civilIdExpiryDate && (
                  <ErrorMessage errorMessage={errors.civilIdExpiryDate} />
                )}
              </Grid>
            </Grid>
            <Grid item container spacing={1}></Grid>
          </Grid>
        </CommonAccordion>
        <CommonAccordion
          defaultExpanded={true}
          arabicLabel={formLabel.headings.nationalityResidency.arabic}
          englishLabel={formLabel.headings.nationalityResidency.english}
        >
          <Grid container spacing={1}>
            <Grid item container spacing={4}>
              <Grid xs={12} md={6} item>
                <Input
                  endornment={null}
                  value={formData.nationality.value}
                  customMenuItemClass={customBorderBottom}
                  handleChange={(e) => {
                    handleChange(e, "nationality");
                  }}
                  arabicLabel={formLabel.nationality.arabic}
                  englishLabel={formLabel.nationality.english}
                  option={countryListOptionWithBedoon}
                  select={true}
                />
                {errors.nationality && (
                  <ErrorMessage errorMessage={errors.nationality} />
                )}
              </Grid>
              <Grid xs={12} md={6} item>
                <Input
                  endornment={null}
                  arabicLabel={formLabel.birthCountry.arabic}
                  englishLabel={formLabel.birthCountry.english}
                  value={formData.birthCountry.value}
                  handleChange={(e) => {
                    handleChange(e, "birthCountry");
                  }}
                  select={true}
                  option={countryListOptionWithBedoon}
                  customMenuItemClass={customBorderBottom}
                />
                {errors.birthCountry && (
                  <ErrorMessage errorMessage={errors.birthCountry} />
                )}
              </Grid>

              {formData.nationality.value === "Non-Kuwaiti" ? (
                <>
                  <Grid xs={12} md={6} item>
                    <Input
                      endornment={null}
                      type={"number"}
                      arabicLabel={formLabel.securityCard.arabic}
                      englishLabel={formLabel.securityCard.english}
                      value={formData.securityCard.value}
                      handleChange={(e) => {
                        if (maxLength(e, 12)) return;

                        handleChange(e, "securityCard");
                      }}
                    />
                    {errors.securityCard && (
                      <ErrorMessage errorMessage={errors.securityCard} />
                    )}
                  </Grid>

                  <Grid xs={12} md={6} item>
                    <Input
                      endornment={null}
                      type={"date"}
                      label={"Security Card Expiry Date"}
                      value={formData.securityCardExpiry.value}
                      handleChange={(e) =>
                        handleChange(e, "securityCardExpiry")
                      }
                    />
                    {errors.securityCardExpiry && (
                      <ErrorMessage errorMessage={errors.securityCardExpiry} />
                    )}
                  </Grid>
                </>
              ) : (
                <>
                  {/* <Grid xs={12} md={6} item>
                    <Input
                      endornment={null}
                      arabicLabel={formLabel.passport.arabic}
                      englishLabel={formLabel.passport.english}
                      value={formData.passport.value}
                      handleChange={(e) => handleChange(e, "passport")}
                    />
                    {errors.passport && (
                      <ErrorMessage errorMessage={errors.passport} />
                    )}
                  </Grid>

                  <Grid xs={12} md={6} item>
                    <Input
                      endornment={null}
                      type={"date"}
                      label={"Passport Expiry Date"}
                      value={formData.passportExpiry.value}
                      handleChange={(e) => handleChange(e, "passportExpiry")}
                    />
                    {errors.passportExpiry && (
                      <ErrorMessage errorMessage={errors.passportExpiry} />
                    )}
                  </Grid> */}
                </>
              )}
            </Grid>
            <Grid item container spacing={1}></Grid>
          </Grid>
        </CommonAccordion>
        <CommonAccordion
          defaultExpanded={true}
          arabicLabel={formLabel.headings.addressDetails.arabic}
          englishLabel={formLabel.headings.addressDetails.english}
        >
          <Grid container spacing={1}>
            <Grid item container spacing={4}>
              <Grid xs={12} md={4} item>
                <Input
                  value={formData.address.block.value}
                  handleChange={(e) => {
                    if (!isNotArabic(e)) return;
                    handleChange(e, "address", "block");
                  }}
                  arabicLabel={formLabel.address.block.arabic}
                  englishLabel={formLabel.address.block.english}
                />
                {errors.addressBlock && (
                  <ErrorMessage errorMessage={errors.addressBlock} />
                )}
              </Grid>
              <Grid xs={12} md={4} item>
                <Input
                  value={formData.address.street.value}
                  handleChange={(e) => {
                    if (!isNotArabic(e)) return;
                    handleChange(e, "address", "street");
                  }}
                  arabicLabel={formLabel.address.street.arabic}
                  englishLabel={formLabel.address.street.english}
                />
                {errors.addressStreet && (
                  <ErrorMessage errorMessage={errors.addressStreet} />
                )}
              </Grid>
              <Grid xs={12} md={4} item>
                <Input
                  value={formData.address.appartment.value}
                  handleChange={(e) => {
                    if (!isNotArabic(e)) return;
                    handleChange(e, "address", "appartment");
                  }}
                  arabicLabel={formLabel.address.appartment.arabic}
                  englishLabel={formLabel.address.appartment.english}
                />
                {errors.addressAppartment && (
                  <ErrorMessage errorMessage={errors.addressAppartment} />
                )}
              </Grid>
              <Grid xs={12} md={4} item>
                <Input
                  value={formData.address.area.value}
                  handleChange={(e) => {
                    if (!isNotArabic(e)) return;
                    handleChange(e, "address", "area");
                  }}
                  arabicLabel={formLabel.address.area.arabic}
                  englishLabel={formLabel.address.area.english}
                />
                {errors.addressArea && (
                  <ErrorMessage errorMessage={errors.addressArea} />
                )}
              </Grid>
              <Grid xs={12} md={4} item>
                <Input
                  value={formData.address.jaddah.value}
                  handleChange={(e) => {
                    if (maxLength(e, 2)) return;
                    handleChange(e, "address", "jaddah");
                  }}
                  arabicLabel={formLabel.address.jaddah.arabic}
                  englishLabel={formLabel.address.jaddah.english}
                  type="number"
                />
                {errors.addressJaddah && (
                  <ErrorMessage errorMessage={errors.addressJaddah} />
                )}
              </Grid>
              <Grid xs={12} md={4} item>
                <Input
                  value={formData.address.country.value}
                  handleChange={(e) => handleChange(e, "address", "country")}
                  arabicLabel={formLabel.address.country.arabic}
                  englishLabel={formLabel.address.country.english}
                  option={countryListOptionWithBedoon}
                  customMenuItemClass={customBorderBottom}
                  select={true}
                />
                {errors.addressCountry && (
                  <ErrorMessage errorMessage={errors.addressCountry} />
                )}
              </Grid>
              <Grid xs={12} md={4} item>
                <Input
                  value={formData.address.po.value}
                  handleChange={(e) => {
                    if (!isNotArabic(e)) return;
                    handleChange(e, "address", "po");
                  }}
                  arabicLabel={formLabel.address.postalOffice.arabic}
                  englishLabel={formLabel.address.postalOffice.english}
                />
                {errors.addressPo && (
                  <ErrorMessage errorMessage={errors.addressPo} />
                )}
              </Grid>
              <Grid xs={12} md={4} item>
                <Input
                  value={formData.address.zipCode.value}
                  handleChange={(e) => {
                    if (!isNotArabic(e)) return;
                    handleChange(e, "address", "zipCode");
                  }}
                  type="number"
                  arabicLabel={formLabel.address.zipCode.arabic}
                  englishLabel={formLabel.address.zipCode.english}
                />
                {errors.addressZipCode && (
                  <ErrorMessage errorMessage={errors.addressZipCode} />
                )}
              </Grid>

              <Grid xs={12} md={4} item>
                <Input
                  value={formData.address.governorate.value}
                  handleChange={(e) =>
                    handleChange(e, "address", "governorate")
                  }
                  arabicLabel={formLabel.address.governorate.arabic}
                  englishLabel={formLabel.address.governorate.english}
                  select={true}
                  option={formData.address.governorate.option}
                />
                {errors.addressGovernorate && (
                  <ErrorMessage errorMessage={errors.addressGovernorate} />
                )}
              </Grid>
            </Grid>
            <Grid item container spacing={1}></Grid>
          </Grid>
        </CommonAccordion>
        <CommonAccordion
          defaultExpanded={true}
          arabicLabel={formLabel.headings.contactDetails.arabic}
          englishLabel={formLabel.headings.contactDetails.english}
        >
          <Grid container spacing={1}>
            <Grid item container spacing={4}>
              <Grid xs={12} md={6} item>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    gap: "0.5rem"
                  }}
                >
                  <FormFieldLabel
                    arabicLabel={formLabel.contactInfo.mobileOne.arabic}
                    englishLabel={formLabel.contactInfo.mobileOne.english}
                  />

                  <PhoneInput
                    inputStyle={{
                      width: "100%",
                      background: "#F6F2F8",
                      border: "1px solid lightgray",
                      borderRadius: "12px",
                      height: "2.6rem"
                    }}
                    country={"kw"}
                    value={formData.mobileOne.value}
                    inputProps={{
                      required: true
                    }}
                    // onlyCountries={['in', 'de', 'ru']}
                    onChange={(value, countryData) => {
                      const countryCodeNumber = countryData?.dialCode;
                      handleChange({ target: { value } }, "mobileOne");
                      handleChange(
                        { target: { value: countryCodeNumber } },
                        "mobileOne",
                        "phoneCode"
                      );
                    }}
                  />
                  {errors.mobileOne && (
                    <ErrorMessage errorMessage={errors.mobileOne} />
                  )}
                </div>
              </Grid>

              <Grid xs={12} md={6} item>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    gap: "0.5rem"
                  }}
                >
                  <FormFieldLabel
                    arabicLabel={formLabel.contactInfo.homeTelNo.arabic}
                    englishLabel={formLabel.contactInfo.homeTelNo.english}
                  />

                  <PhoneInput
                    inputStyle={{
                      width: "100%",
                      background: "#F6F2F8",
                      border: "1px solid lightgray",
                      borderRadius: "12px",
                      height: "2.6rem"
                      /* Add any additional styles you need */
                    }}
                    country={"kw"}
                    value={formData.homeTelOne.value}
                    onChange={(value, countryData) => {
                      const countryCodeNumber = countryData?.dialCode;
                      handleChange({ target: { value } }, "homeTelOne");
                      handleChange(
                        { target: { value: countryCodeNumber } },
                        "homeTelOne",
                        "phoneCode"
                      );
                    }}
                  />
                  {errors.homeTelOne && (
                    <ErrorMessage errorMessage={errors.homeTelOne} />
                  )}
                </div>
              </Grid>

              <Grid xs={12} md={6} item>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    gap: "0.3rem"
                  }}
                >
                  <FormFieldLabel
                    arabicLabel={formLabel.contactInfo.emailAddress.arabic}
                    englishLabel={formLabel.contactInfo.emailAddress.english}
                  />

                  <TextField
                    // required={true}
                    size={"small"}
                    color="primary"
                    value={formData.emailOne.value}
                    disabled
                    onChange={(e) => handleChange(e, "emailOne")}
                    // id="outlined-start-adornment"
                    // sx={{ m: 1, width: '100%' }}
                    sx={{
                      background: "#F6F2F8",
                      width: "100%",
                      borderColor: "lightgray",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "none",
                          borderRadius: "8px" // Specify your desired border color here
                        },

                        "&.Mui-focused fieldset": {
                          borderColor: "black" // Specify your desired focused border color here
                        }
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailOutlinedIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                  {errors.emailOne && (
                    <ErrorMessage errorMessage={errors.emailOne} />
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid item container spacing={1}></Grid>
          </Grid>
        </CommonAccordion>
      </Box>
    </Box>
  );
};

export default AdmissionForm;
