import { Box } from "@mui/material";
import { GoCheckCircleFill } from "react-icons/go";
import { MdFileUploadOff } from "react-icons/md";
import { useRef, useState } from "react";
import Resizer from "react-image-file-resizer";

const Download = ({ formData, handleChange, value, errors }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const handleFileUpload = async (e) => {
    const selectedFile = e.target.files[0];
    console.log(selectedFile);

    let compressedImage;
    if (selectedFile.size / 1000 > 1024 && selectedFile.type === "image/jpeg") {
      compressedImage = await resizeFile(selectedFile);
      console.log("COMPRESS", compressedImage);
    }

    if (!selectedFile) {
      console.log("No file is selected");
    }

    const updatedFormData = {
      target: {
        value: compressedImage || selectedFile
      }
    };
    handleChange(updatedFormData, "document", value);
    setSelectedFile(updatedFormData);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "0.5rem"
      }}
    >
      <div
        className={`${
          !selectedFile && "bg-slate-200"
        } rounded-lg flex items-center justify-center w-[80px] h-[66px]`}
      >
        {!selectedFile ? (
          <MdFileUploadOff className="text-[50px] text-slate-500" />
        ) : (
          <GoCheckCircleFill className="text-[50px] text-[#17bebb]" />
        )}
      </div>
      <input
        type="file"
        accept="image/jpeg,image/jpg,image/png,application/pdf"
        ref={fileInputRef}
        onChange={handleFileUpload}
        style={{ display: "none" }}
      />
      <button
        type="button"
        disabled={selectedFile}
        onClick={handleButtonClick}
        className={`${
          !selectedFile
            ? "bg-[#0466c8] hover:bg-blue-500"
            : "bg-slate-500 opacity-55 hidden"
        }  h-full px-4 lg:py-4 py-2 text-white font-bold rounded-lg `}
      >
        Upload Document
      </button>
    </Box>
  );
};

export default Download;
