import React, { useState } from "react";
import { FaHashtag } from "react-icons/fa";
import OtpInput from "react-otp-input";
import { BASE_URL } from "../utils/config";
const SubmitOTP = ({ email, stepHandler }) => {
  const [verifyOtp, setVerifyOtp] = useState("");
  const [isError, setIsError] = useState("");

  const handleVerifyOTP = async () => {
    try {
      const response = await fetch(`${BASE_URL}/service/email-verify`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email: email,
          otp: verifyOtp
        })
      });
      const json = await response.json();
      if (json.status) {
        stepHandler();
      } else {
        setIsError("Invalid OTP");
      }
      // console.log(json);
    } catch (error) {
      setIsError(error.message);
    }
  };

  return (
    <div
      // style={{
      //   background:
      //     "linear-gradient(to bottom, rgba(0,0,0,0.70) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.90) 100%), url('/form.png')",
      //   backgroundPosition: "center",
      //   backgroundSize: "cover",
      //   backgroundRepeat: "no-repeat",
      //   backgroundAttachment: "fixed"
      // }}
      className="w-screen h-screen bg-gradient-to-r from-[#00a6fb] to-[#0466c8] bg-[#0466c8]   flex items-center justify-center"
    >
      <div className=" py-4 w-[360px] md:w-[400px] bg-[#edf2f4] rounded-2xl shadow-xl md:px-10 px-4 flex flex-col items-start justify-center gap-4">
        <div className="flex w-full items-center justify-center flex-col">
          <FaHashtag className="text-[100px] text-[#0466c8]" />
          <h1 className="md:text-3xl text-2xl font-bold font-sans inline-block">
            Submit OTP
          </h1>
          <p className="font-semibold text-[#5d6677]">
            OTP sent to your email, check your email
          </p>
        </div>
        <div className="flex flex-col items-center gap-2 w-full">
          <div className=" h-20 items-center justify-center flex gap-3 w-full">
            <div className="otp-input">
              <OtpInput
                value={verifyOtp}
                onChange={setVerifyOtp}
                numInputs={6}
                inputType="number"
                renderSeparator={<span> </span>}
                placeholder={"000000"}
                renderInput={(props) => <input {...props} />}
              />
            </div>
            {/* <input
              value={verifyOtp[0]}
              type="number"
              maxLength={1}
              placeholder="0"
              className="appearance-none rounded-xl outline-0 w-10 h-full text-center text-2xl"
              onChange={(e) => {
                handleOtpChange(0, e);
              }}
            />
            <input
              value={verifyOtp[1]}
              type="number"
              maxLength={1}
              placeholder="0"
              className="appearance-none rounded-xl outline-0 w-10 h-full text-center text-2xl"
              onChange={(e) => {
                handleOtpChange(1, e);
              }}
            />
            <input
              value={verifyOtp[2]}
              type="number"
              maxLength={1}
              placeholder="0"
              className="appearance-none rounded-xl outline-0 w-10 h-full text-center text-2xl"
              onChange={(e) => {
                handleOtpChange(2, e);
              }}
            />
            <input
              value={verifyOtp[3]}
              type="number"
              maxLength={1}
              placeholder="0"
              className="appearance-none rounded-xl outline-0 w-10 h-full text-center text-2xl"
              onChange={(e) => {
                handleOtpChange(3, e);
              }}
            />
            <input
              value={verifyOtp[4]}
              type="number"
              maxLength={1}
              placeholder="0"
              className="appearance-none rounded-xl outline-0 w-10 h-full text-center text-2xl"
              onChange={(e) => {
                handleOtpChange(4, e);
              }}
            />
            <input
              value={verifyOtp[5]}
              type="number"
              maxLength={1}
              placeholder="0"
              className="appearance-none rounded-xl outline-0 w-10 h-full text-center text-2xl"
              onChange={(e) => {
                handleOtpChange(5, e);
              }}
            /> */}
          </div>
          <button
            onClick={handleVerifyOTP}
            disabled={verifyOtp.length < 6}
            className="px-4 py-3 rounded-xl bg-[#2b2d42] text-white disabled:bg-gray-300 disabled:text-gray-600 font-bold hover:bg-[#8d99ae] hover:text-[#2b2d42] w-full mt-3"
          >
            Verify
          </button>
        </div>
        {isError && (
          <p className="font-bold text-[#B70042] w-full text-center">
            {isError}
          </p>
        )}
      </div>
    </div>
  );
};

export default SubmitOTP;
